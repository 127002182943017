
.cart-checkout-item_component {
  position: relative;
  .cart-item_wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-items: flex-start;
    margin: 30px 30px 0;
    padding: 0 0 50px;
    border-bottom: 1px solid #e5e5e4;
    .cart-item {
      &_img_wrapper {
        .cart-item_img {
          flex: 0 0 auto;
          width: 85px;
          height: 85px;
          background-size: cover;
          background-position: center center;
          margin-right: 1rem;
          flex-shrink: 0;
          border-radius: 4px;
        }
        .cart-item_status {
          display: none;
          margin-top: 16px;
          @media screen and (max-width: 900px) {
            display: block;
          }
        }
        .cart-item_price-total {
          display: none;
          margin-top: 16px;
          @media screen and (max-width: 700px) {
            display: block;
            text-align: left;
          }
        }
      }
      &_details {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #111111;
        .cart-item_title {
          font-weight: 600;
          font-size: 20px;
          color: #111111;
          letter-spacing: 0;
          @media screen and (max-width: 768px) {
            margin-bottom: 2px;
          }
          a {
            color: #111111;
          }
        }
        .cart-item_totals {
          display: flex;
          .cart-item_size {
            margin-right: 5px;
          }
          .cart-item_size,
          .cart-item_inventory {
            font-weight: 100;
            font-size: 14px;
            color: #111111;
            letter-spacing: 0;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
              margin-bottom: 3px;
            }
          }
        }
        .cart-item_subtotal {
          margin-bottom: 30px;
          .cart-item_quantity {
            border: none;
            margin-top: 4px;
            display: flex;
            a {
              display: block;
              width: 30px;
              height: 23px;
              border: 1px solid #009f5e;
              border-radius: 4px;
              font-size: 18px;
              text-align: center;
              line-height: 1;
              text-decoration: none;
              cursor: pointer;
              &.disabled {
                cursor: not-allowed;
              }
              &.remove {
                background: white;
                color: #009f5e;
              }
              &.add {
                background: #009f5e;
                color: white;
              }
            }
            span {
              width: auto;
              padding: 0 30px;
            }
          }
        }
        .cart-item_action_links {
          display: flex;
          flex-direction: column;
          a {
            cursor: pointer;
          }
          a.save {
            font-size: 14px;
            color: #757575;
            letter-spacing: 0;
            margin-bottom: 15px;
            text-decoration: underline;
          }
          a.delete {
            font-size: 14px;
            color: #c70000;
            letter-spacing: 0;
            text-decoration: underline;
          }
        }
      }
      &_status {
        flex: 0 0 15%;
        .badge-inventory {
          span {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      &_receive_options {
        flex: 0 1 auto;
        .receive_option {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          .input_wrapper {
            label {
              font-size: 16px;
              color: #000000;
              letter-spacing: 0;
              margin-bottom: 0;
            }
            input {
              &:disabled {
                appearance: none;
                background-color: var(--bg-color);
                background-image: url("/assets/images/disabled-radio-option.png");
                width: 12px;
                height: 12px;
                background-size: cover;
              }
            }
          }
          span {
            font-size: 14px;
            color: #31bc4d;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
      }
      &_price-total {
        flex: 1 0 auto;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        letter-spacing: 0;
        text-align: right;
        @media screen and (max-width: 700px) {
          display: none;
        }
      }

      @media screen and (max-width: 700px) {
        &_details {
          flex: 1;
        }
        &_receive_options {
          flex: 1;
        }
      }
    }
    @media screen and (max-width: 568px) {
      flex-direction: column;
      margin: 30px 15px 0;
      .cart-item_img_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        .cart-item_status,
        .cart-item_price-total {
          margin-top: 0;
          align-self: center !important;
        }
      }
      .cart-item_details {
        margin-bottom: 16px;
      }
    }
  }
  .remove-confirm {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparentize(#fff, 0.15);
    transition: all 0.3s;
    opacity: 0;
    display: none;

    &.in {
      display: flex;
      opacity: 1;
    }

    .confirm,
    .cancel {
      padding: 10px 15px !important;

      & + button,
      & + a {
        margin-left: 1rem;
      }
    }

    p {
      margin-bottom: 0.5rem;
      padding: 0 15px;
      // color: #fff;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
