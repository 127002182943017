// Transparent Buttons
// - clear background, white text, meant for use over imagery or colored backgrounds, brand color on hover
.btn-transparent {
	background-color: transparent !important;
	color: #fff !important;
	border-width: 2px;
	@each $color in $brand-colors {
		&.btn-#{"" + nth($color, 1)} {
			border-color: nth($color, 2) !important;
			&:hover,
			&:focus,
			&:active {
				background-color: nth($color, 2) !important;
			}
		}
	}
}

// Outline Buttons
// - clear background, brand color outline and font, brand color and white text on hover
.btn-outline {
	background-color: transparent !important;
	@each $color in $brand-colors {
		&.btn-#{"" + nth($color, 1)} {
			color: nth($color, 2) !important;
			border-color: nth($color, 2) !important;
			&:hover,
			&:focus,
			&:active {
				color: #fff !important;
				background-color: nth($color, 2) !important;
			}
		}
	}
}

.btn {
	@include ff-sans-serif();
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 23px;
	padding: 12px 21px 13px;
	border-radius: 4px;
	//border: none;
	//color: #FFF;
	transition: all .4s ease;

		&.extra-padding {
			padding-left: 66px;
			padding-right: 66px;
		}

		// &.btn-primary {
		// 	background-color: #128851;
		// 	color: #FFF;

		// 		&:hover {
		// 			background-color: #66AD42;
		// 		}
		// }

		&.btn-darker {
			background-color: #128851;
			color: #FFF;

				&:hover {
					background-color: #008645;
				}
		}

		&.btn-darker-outline {
			background-color: #FFF;
			color: #128851;
			border: solid 1px #128851;

				&:hover {
					background-color: #128851;
					color: #FFF;
				}
		}

		&.btn-alt {
			background-color: #39261F;
			color: #FFF;

				&:hover {
					background-color: #200D06;
				}
		}

		&.btn-white {
			background-color: #FFF;
			color: #4A4A4A;
			border:2px solid #E6E6E6;
			color: #919DA7;

				&:hover {
					background-color: #E6E6E6;
				}
		}

		&.border-only {
			border: 2px solid #FFF;
			border-radius: 3px;

				&:hover {
					background-color: #FFF;
				}
		}

		// &.btn-clear {
		// 	background: transparent;
		// 	border: 2px solid #9B9B9B;
		// 	color: #9B9B9B;

		// 		&:hover {
		// 			background-color: #9B9B9B;
		// 			color: #F3F5F5;
		// 		}
		// }

		// &.btn-transparent {
		// 	background-color: transparent;

		// 		&.white-border {
		// 			border: 2px solid #FFF;
		// 			color: #FFF;

		// 				&:hover {
		// 					background-color: #FFF !important;
		// 					color: #4A4A4A !important;
		// 				}
		// 		}
		// }
}
