
$border-color: #dee2e6;
$border: 2px solid $border-color;

.table.table-order {
    // border-radius: 1rem;
    // overflow: hidden;
    box-shadow: 0px 2px 4px rgba(#000, .1);
    border-top: none;

    thead th {
        position: sticky;
        top: calc(69px + 104px);
        z-index: 2;
    }

    th, td {
        border-top: $border;
        vertical-align: middle;
    }

    tbody {
        border-left: $border;
        border-right: $border;   
        font-size: 16px;
    }
    
    thead {
        th {
            border-top: 2px solid #128851;
            background: #128851;
            color: #fff;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}
@media screen and (max-width: 600px) {
    .table.table-order:not(.checkout-cart){
        thead {
            display: none;
        }

        tr{

            td {
                display: flex;
                font-size: 16px;
                font-weight: 400;


                &:before{
                    content: attr(data-col);
                    font-weight: 400;
                    width: 120px;
                    min-width: 120px;
                    text-transform: capitalize;
                    font-size: 16px;
                }

                &:first-child{
                    &:before{
                        display: none;
                    }
                }
            }
        }

    } 
    .table.table-order.checkout-cart{
        thead {
            display: none;
        }

        tr{
            margin-bottom: 0;
            border-radius: 0;
            display: block;
            border: none;
            border-bottom: $border;

            td {
                display: flex;
                font-size: 16px;
                font-weight: 400;
                
                &[data-col="title"]{
                    background: white;
                }


                &:before{
                    content: attr(data-col);
                    font-weight: 400;
                    width: 120px;
                    min-width: 120px;
                    text-transform: capitalize;
                    font-size: 16px;
                }

                &:first-child{
                    &:before{
                        display: none;
                    }
                }
            }
        }

    } 
}
