#landscape-design-form {
    .uploaded-images {
        display: flex;
        flex-wrap: wrap;
        .image {
            position: relative;
            margin: 15px;
            img {
                object-fit: contain;
            }
            button {
                background-color: $primary;
                color: $white;
                border-radius: 25px;
                border: none;
                position: absolute;
                top: -20px;
                right: -20px;
                width: 40px;
                height: 40px;
                font-size: 20px;
            }
        }
    }
}
