
span, a {
    display: inline-block;
}
a {
    margin-left: .5rem;
    cursor: pointer;
}
span{
    margin-left: .5rem;
}
img{
    margin-left: .5rem;
    vertical-align: sub;
}
.title {
    text-decoration: underline;
    margin-left: 0;
}
.green {
    color: #128851 !important;
}
.red {
    color: #FF3434 !important;
}
