
#natorps_icons_box {
  margin-bottom: 10px;
}
.natorps_icons_box .cls-1 {
  fill: none;
}
.natorps_icons_box .cls-2 {
  fill: #fff;
}
