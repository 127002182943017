//@border support

// .b-DIR-SIZE-COLOR
// add a direction specific border to an element

$dirs: (t,b,l,r,x,y,a);
@for $i from 0 through 10 {
	@each $dir in $dirs {
		@each $col in $brand-colors {
			.b-#{$dir}-#{$i}-#{"" + nth($col, 1)} {
				$unit: $i+px;
				$color: nth($col, 2);
				@if $dir == t or $dir == y or $dir == a { border-top: $unit solid $color; } 
				@if $dir == b or $dir == y or $dir == a { border-bottom: $unit solid $color; }
				@if $dir == l or $dir == x or $dir == a { border-left: $unit solid $color; }
				@if $dir == r or $dir == x or $dir == a { border-right: $unit solid $color; }
			}
		}
	}
}

$breaks:  mapReverse($grid-breakpoints);
@each $bp in $breaks {
	@media screen and (max-width: #{nth($bp, 2)}) {
		.no-border-#{"" + nth($bp, 1)} {
			border:none;
		}
	}
}