
#natorps_icons_truck {
  margin-bottom: 10px;
}

.natorps_icons_truck .cls-1 {
  fill: none;
}
.natorps_icons_truck .cls-2 {
  fill: #fff;
}
