section.plant-details {
	background: #eeeeee;
	padding: 2rem 0;

	@media screen and (max-width: 768px) {
		padding: 0;
	}

	> .container {
		@media screen and (max-width: 768px) {
			padding: 0;
		}

		> .row {
			@media screen and (max-width: 768px) {
				margin: 0;
			}

			> div {
				@media screen and (max-width: 768px) {
					padding: 0;
				}

				> .row {
					@media screen and (max-width: 768px) {
						margin: 0;
					}

					> div {
						@media screen and (max-width: 768px) {
							padding: 0;
						}
					}
				}
			}
		}
	}

	.plant-detail-wrap {
		.go-back {
			background-color: #fff;
			padding: 24px 32px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom: 1px solid #e5e5e4;
			border-left: 1px solid #e5e5e4;
			border-right: 1px solid #e5e5e4;

			@media screen and (max-width: 768px) {
				display: none;
			}

			p {
				margin: 0;
				padding: 0;
				@include ff-sans-serif();
				font-weight: 600;
				font-size: 16px;
				color: #128851;
				letter-spacing: 0;
				display: flex;
				align-items: center;

				.fa {
					margin-right: 9px;
				}
			}
		}

		.plant-main-panel {
			background-color: #fff;
			// padding-top: 18px;
			// padding-left: 32px;
			// padding-right: 32px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			border-left: 1px solid #e5e5e4;
			border-right: 1px solid #e5e5e4;

			@media screen and (max-width: 768px) {
				border: none;
				padding: 0;
				display: flex;
				flex-direction: column;
			}
			.back-botton {
				padding-top: rem-val(18);
				padding-left: rem-val(32);
				padding-right: rem-val(32);
				.back-arrow {
					background-color: transparent;
					border: none;
					color: $primary;
					font-size: 16px;
					@include ff-sans-serif();
					font-weight: 600;
					img {
						margin-right: 10px;
					}
				}
			}

			.plant-details {
				padding-top: rem-val(18);
				padding-left: rem-val(32);
				padding-right: rem-val(32);
				.plant-status {
					.badge-inventory {
						font-size: rem-val(14);
						padding: 5px 15px;
						margin-bottom: 5px;
					}
				}
				.common-name {
					font-weight: bold;
					font-size: 40px;
					color: #39261f;
					letter-spacing: 0;
					line-height: 50px;
					margin-bottom: 7px;
					@extend .ff-base;

					@media screen and (max-width: 768px) {
						padding-left: 15px;
						padding-right: 15px;
						order: 2;
						font-size: 20px;
						line-height: 1.3;
						margin-bottom: 3px;
					}
				}

				.botanical-name {
					@include ff-sans-serif();
					font-size: 18px;
					color: #111111;
					letter-spacing: 0;
					line-height: 23px;
					margin: 0;
					margin-bottom: 27px;

					@media screen and (max-width: 768px) {
						padding-left: 15px;
						padding-right: 15px;
						order: 3;
						font-size: 14px;
						line-height: 1.3;
						font-weight: 300;
						margin-bottom: 15px;
					}

					span {
						@include ff-sans-serif();
						font-weight: 600;
					}
				}

				.plant-image-wrap {
					min-height: 450px;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					margin-bottom: 34px;
					position: relative;

					@media screen and (max-width: 768px) {
						min-height: 40vh;
						order: 1;
						margin-bottom: 17px;
					}

					.picture-credit {
						position: absolute;
						bottom: 0;
						right: 1rem;
						color: #fff;
						padding-left: 1rem;
						text-shadow: 1px 1px #111111;
					}
				}

				.plant-description {
					padding-bottom: 34px;

					@media screen and (max-width: 768px) {
						padding-left: 15px;
						padding-right: 15px;
						order: 4;
						padding-bottom: 0;
					}

					p {
						@include ff-sans-serif();
						font-size: 20px;
						color: #111111;
						letter-spacing: 0;
						line-height: 29px;

						@media screen and (max-width: 768px) {
							font-size: 14px;
							line-height: 1.3;
						}

						&.about {
							@media screen and (max-width: 768px) {
								display: none;
							}
						}
					}
				}
			}
		}

		.share-plant {
			background: #f5f6f5;
			border: 1px solid #e5e5e4;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
			padding: 18px 32px;

			&.desktop {
				display: block;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}

			&.mobile {
				display: none;

				@media screen and (max-width: 768px) {
					display: block;
				}
			}

			.share-icons {
				p {
					margin: 0;
					padding: 0;
					// font-family: SourceSansPro-Regular;
					@include ff-sans-serif();
					font-size: 18px;
					color: #111111;
					letter-spacing: 0;
					margin-right: 8px;
				}

				.email {
					margin-right: 8px;
					font-size: 24px;
					color: #707070;
				}

				.facebook {
					margin-right: 8px;
					font-size: 24px;
					color: #3664a2;
				}

				.pinterest {
					font-size: 24px;
					color: #dc1606;
				}
			}
		}
	}

	.plant-detail-panel {
		.heading {
			.wrap {
				background-color: #fff;
				padding: 27px 32px;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				border-bottom: 1px solid #e5e5e4;
			}

			h3 {
				@include ff-sans-serif();
				font-weight: 700;
				font-size: rem-val(24);
				color: #111111;
				letter-spacing: 0;
				line-height: 20px;
			}
		}
		.detail {
			background-color: white;
			&.last {
				.wrap {
					box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
				}
			}

			.wrap {
				background-color: #fff;
				padding: 24px 32px;
				border-bottom: 1px solid #e5e5e4;
			}

			h4, p {
				@include ff-sans-serif();
				font-size: rem-val(16);
				color: #111111;
				line-height: 20px;
				font-weight: 400;
				margin: 0;
			}
		}
	}

	// add to cart mobile
	.add-to-cart-wrapper {
		@include media-breakpoint-down(lg) {
			&.items-added {
				.add-to-cart_body {
					border-bottom-color: #fff;
				}
				.add-to-cart_footer {
					position: sticky;
					bottom: 0;

					border-top: 1px solid #e5e5e5;

					&:not(.scrolled-into-view) {
						animation: fadeInUp 0.2s;
					}
				}
			}
		}
	}
}
