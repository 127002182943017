//@group cart slideout

[v-cloak] {
    display: none;
}

//@variables
$accent_grey: #e5e5e4;
$accent_green: #128851;
$accent_brown_light: #45371e;
$accent_brown_dark: #372d18;

//@Cart Indicator - Navbar
.nav-link--cart,
[data-bind="cart-count"],
[data-bind="shopping-list-count"] {
    position: relative;

    .cart-count {
        position: absolute;
        top: -14px;
        right: -10px;
        display: block;
        width: 17px;
        height: 17px;

        text-align: center;
        font-family: "Source Sans Pro";
        font-weight: 800;
        font-size: 12px;
        line-height: 15px;
        background-color: rgba(#fff, 0.16);
        color: rgba(#fff, 0.7);
        border-radius: 50%;
    }
}

//@Toggle
#cart-toggle {
    position: relative;

    .status {
        position: absolute;
        top: calc(100% + 1rem);
        // color: #fff;
        background-color: #fff;
        border-radius: 3px;
        //padding: .5rem 1rem;
        z-index: 100;
        //left: -9rem;
        right: -10px;

        @media screen and (max-width: 500px) {
            position: fixed;
            top: 60px;
            left: 16px;
            right: 16px;
        }

        font-size: 15px;
        // box-shadow: 0 0 6px rgba(0, 0, 0, .2);
        display: none;
        transition: all 0.3s;

        border: 2px solid $primary;

        &.in {
            display: block;
        }

        &:before {
            content: "";
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid $primary;
            width: 0;
            height: 0;
            position: absolute;
            top: -10px;
            right: 1rem;
        }

        @media screen and (max-width: 500px) {
            &:before {
                display: none;
            }
        }

        .shopping-list-item-added {
            font-size: 16px;
            font-weight: normal;
            text-align: center;
            padding: 20px 13px;
            min-width: 180px;
        }

        .products-added {
            .total-added {
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                border-bottom: 1px solid #e5e5e4;
                padding: 20px 13px;
            }
            .close-text {
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                padding: 15px 13px;
            }

            .product {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e5e5e4;
                padding: 18px 13px;
                text-align: left;

                .product-image {
                    width: 79px;
                    height: 79px;
                    border-radius: 4px;
                    background-size: cover;
                    background-position: center;
                    margin-right: 29px;
                    flex-shrink: 0;
                }

                .product-info {
                    min-width: 150px;
                    line-height: 1.3;

                    .product-name {
                        color: #111111;
                        font-size: 16px;
                    }

                    .product-size {
                        margin-top: 13px;
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

//@Backdrop
.cart-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s;
    animation-duration: 0.2s;
    z-index: 1010;

    //@shown
    &.in {
        display: block;
    }
}

//@Cart

// @slideout variables
$header_height: 68px;
$toggle_height: 47px;
$updating_height: 50px;
$footer_height: 185px;
$footer_height_mobile: 157px;
$footer_height_small: 59px;
.cart-slideout,
.slide-out-body {
    display: none;

    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;

    width: 400px;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    z-index: 1011;
    // Update to compete with LiveChat
    //z-index: calc(2147483639 + 100);

    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

    transition: all 0.3s;
    animation-duration: 0.2s;

    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);

    //@shown
    &.in {
        display: block;
    }

    .vueContainer {
        > div {
            position: absolute;
            width: 100%;
            padding: 1rem;
        }

        //@header
        .cart-header {
            top: 0;
            height: $header_height;
            border-bottom: 2px solid $accent_green;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            z-index: 3;
            background-color: $accent_green;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

            $base: 2.5rem;

            .count {
                font-size: 1.75rem;
                color: #fff;
                margin-top: 3px;
                margin-bottom: 0;

                @media screen and (max-width: 768px) {
                    font-size: 1.5rem;
                }
            }

            .total {
                font-size: 1.75rem;
                width: $base;
            }

            .close-wrap {
                button.close {
                    width: $base;
                    height: $base;
                    opacity: 1;
                    color: #fff;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        width: $base;
                        height: 2px;
                        transition: all 0.3s;
                        background-color: white;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }

                    &:hover {
                        &:before,
                        &:after {
                            transform: rotate(0deg);
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        //@contents
        .cart-contents {
            z-index: 1;
            height: calc(100vh - #{$header_height} - #{$toggle_height} - #{$footer_height});
            top: $header_height + $toggle_height;
            padding: 0;
            overflow: scroll;
            overflow-x: hidden;
            transition: all 0.3s;
            position: absolute;

            @media screen and (max-width: 768px) {
                height: calc(100vh - #{$header_height} - #{$toggle_height} - #{$footer_height_mobile});
            }

            &:after {
                display: none;
                content: "Cart Empty";
                position: absolute;
                left: 50%;
                top: 50%;
                font-size: 1.5rem;
                color: $accent_grey;
                transform: translate(-50%, -50%);
            }
        }

        //@cart-pane-toggle
        .cart-pane-toggle {
            position: absolute;
            padding: 0;
            top: $header-height;
            bottom: $footer_height;
            width: 100%;
            left: 0;
            right: 0;
            max-width: 100%;

            @media screen and (max-width: 768px) {
                bottom: $footer_height_mobile;
            }

            .btn-group {
                display: flex;
                flex-wrap: nowrap;
                position: relative;
                width: 100%;
                max-width: 100%;

                button {
                    flex: 0 0 50%;
                    width: 50%;
                    max-width: 50%;
                    font-size: 14px;
                    border-radius: 0px;
                    padding: 10px 30px;
                }
            }

            ul.slideout-ul {
                list-style: none;
                padding-left: 0;

                li {
                    border-bottom: 1px solid #979797;
                    font-size: 24px;
                    color: #575656;
                    letter-spacing: 0;

                    a {
                        color: #575656;
                        font-weight: 600;
                        text-decoration: none;
                        display: block;
                        padding: 19px 40px;
                    }

                    &:hover {
                        background: #d8d8d8;

                        a {
                            color: black;
                        }
                    }
                }
            }

            .ctas {
                padding: 16px;
            }
        }

        //@cart-empty
        .cart-empty {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                text-align: center;

                img {
                    width: 5rem;
                    height: auto;
                }

                p {
                    font-size: 1.5rem;
                    color: darken($accent_grey, 30%);
                }

                a {
                    font-size: 1.1rem;
                }
            }
        }

        //@cart-update
        .cart-updating {
            background-color: $accent_green;
            color: #fff;
            text-align: center;
            top: ($header_height - $updating_height);
            height: $updating_height;
            overflow: hidden;
            left: 0;
            right: 0;
            z-index: 1;
            transition: all 0.3s;
            padding: 0;

            .cart-updating_wrapper {
                padding: 1rem;
            }
        }

        &.updating {
            .cart-contents {
                height: calc(100vh - #{$header_height} - #{$footer_height} - #{$updating_height});
                top: ($header_height + $updating_height);

                @media screen and (max-width: 768px) {
                    height: calc(100vh - #{$header_height} - #{$footer_height_mobile} - #{$updating_height});
                }
            }

            .cart-updating {
                top: $header_height;
            }
        }

        //@footer
        .cart-footer {
            z-index: 3;
            bottom: 0;
            height: auto;
            border-top: 2px solid $accent_brown_dark;
            padding: 0;
            background-color: $accent_brown_light;
            color: #fff;

            > div {
                padding: 1rem;

                p {
                    margin: 0;
                }
            }

            .totals {
                border-bottom: 1px solid $accent_brown_dark;

                @media screen and (max-width: 768px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .subtotal {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                }

                .tax {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                }

                .total {
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    font-weight: bold;
                    margin-top: 0.3rem;

                    @media screen and (max-width: 768px) {
                        margin-top: 0;
                    }
                }
            }

            .account {
                // display: none;
                background-color: $accent_brown_dark;

                // text-align: center;
                .account-link,
                .logout-link {
                    color: #fff;
                    text-decoration: underline;

                    & + a {
                        margin-left: 1rem;
                    }
                }
            }
        }

        //@shopping-list
        $shopping_list_footer_height: 136px;

        .shopping-list {
            position: absolute;
            top: $header-height + $toggle_height;
            height: calc(100vh - #{$header_height} - #{$toggle_height});
            padding: 0;
            overflow: hidden;

            // @shopping list body
            .shopping-list-items {
                position: absolute;
                top: 0;
                // bottom: $shopping_list_footer_height;
                // height: auto;
                height: calc(100% - #{$shopping_list_footer_height});
                left: 0;
                right: 0;
                z-index: 2;
                overflow: scroll;
                overflow-x: hidden;
            }

            // @shopping list footer
            .shopping-list-footer {
                z-index: 3;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                height: auto;
                color: #fff;

                .shopping-list-email {
                    padding: 16px;
                    border-top: 2px solid $accent_brown_dark;
                    background-color: $accent_brown_light;
                }

                .shopping-list-links {
                    padding: 16px;
                    background-color: $accent_brown_dark;

                    a {
                        color: $accent_grey;
                        text-decoration: underline;
                    }
                }

                .shopping-list-confirm-empty {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    border-top: 2px solid $accent_grey;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    p {
                        text-align: center;
                        margin-bottom: 10px;
                        color: $body-color;
                    }

                    div {
                        button {
                            & + button {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }

            // @shopping list items
            .cart-item {
                .cart-item_details {
                    justify-content: center;

                    .cart-item_title {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }

    .slide-out-body {
        .cart-contents {
            height: calc(100vh - #{$header_height} - #{$footer_height_small});
        }

        //@cart-pane-toggle
        .cart-pane-toggle {
            top: $header-height;
            bottom: $footer_height_small;
            overflow-y: scroll;
        }
    }

    // @.add-to-cart
    .add-to-cart {
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;

        .add-to-cart_header {
            background-color: #fff;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            border-bottom: 1px solid #e5e5e4;
            padding: 24px 32px;
            text-align: center;

            @media screen and (max-width: 768px) {
                display: none;
            }

            p {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
            }
        }

        .add-to-cart_body {
            background-color: #fff;
            border-bottom: 1px solid #e5e5e4;
            padding: 0 34px;

            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }
        }

        .add-to-cart_footer {
            background-color: #fff;
            padding: 1rem 32px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;

            .btn {
                font-size: 16px;
                padding: 0.5rem;
            }
        }
    }

    .upsell-cart-item {
        border-bottom: 1px solid #e5e5e4;
        .form-group {
            display: flex;
            border-top: 1px solid #e5e5e4;

            .product-image {
                margin-top: 15px;
                width: 85px;
                height: 85px;
                background-size: cover;
                background-position: center center;
                margin-right: 1rem;
                flex-shrink: 0;
                border-radius: 4px;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
            .product-option {
                flex-basis: 100%;
            }
        }
    }

    //@product-option - used on detail page
    .product-option {
        padding: 15px 25px;
        border-top: 1px solid #e5e5e4;

        &-header {
            display: flex;
            justify-content: space-between;
            padding: 0.75rem 1.5rem;
        }

        .upsell-cart-item & {
            border: 0;
        }

        @media screen and (max-width: 768px) {
            padding: 15px 11px;
        }

        &:first-child {
            border: none;
        }

        .product-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 16px;

            .quantity-available {
                font-weight: normal;
            }
        }

        .product-cart-quantity {
            border: 2px solid $primary;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;

            button,
            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            span {
                width: 34px;
                height: 26px;
                font-size: 18px;
                font-weight: 600;
            }

            button {
                width: 34px;
                height: 34px;
                line-height: 30px;
                padding: 0;
                border: none;
                transition: all 0.3s;
                color: $primary;
                font-weight: 600;
                font-size: 21px;
                background-color: $white;
                border: 2px solid $primary;
                margin: -2px 0 -2px -2px;
                border-radius: 4px;

                &.remove {
                    &.trash {
                        font-size: 20px;
                        font-size: 400;
                    }
                }

                &.add {
                    margin: -2px -2px -2px 0;
                }

                &:hover {
                    cursor: pointer;
                    color: $white;
                    background-color: $primary;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }

        .product-unavailable {
            line-height: 1.3;
        }
    }

    //@cart-item - used on cart flyout
    .cart-item {
        padding: 1rem 2.5rem 1rem 1rem;
        border-bottom: 1px solid $accent_grey;
        position: relative;
        z-index: 1;
        transition: all 0.3s;

        @media screen and (max-width: 768px) {
            padding: 1rem 2rem 1rem 1rem;

            .notes-wrapper & {
                box-shadow: none;
                padding: 1rem 1rem 1rem 0;
            }
            .notes-wrapper .px-0 & {
                box-shadow: none;
                padding: 1rem 1rem 1rem 1rem;
            }
        }

        td {
            padding: 0 !important;
            .wholesale-checkout & {
                padding: 0.5rem 1rem !important;
            }
        }

        @include on-action() {
            z-index: 2;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);

            .notes-wrapper & {
                box-shadow: none;
            }
        }

        .remove-item {
            position: absolute;
            right: 0.5rem;
            top: 1rem;
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $gray-600;
                transition: all 0.3s;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:hover {
                &:before,
                &:after {
                    transform: rotate(0deg);
                    background-color: $accent_green;
                }
            }
        }

        .remove-confirm {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: transparentize(#fff, 0.15);
            transition: all 0.3s;
            opacity: 0;
            display: none;

            &.in {
                display: flex;
                opacity: 1;
            }

            .confirm,
            .cancel {
                padding: 10px 15px !important;

                & + button,
                & + a {
                    margin-left: 1rem;
                }
            }

            p {
                margin-bottom: 0.5rem;
                padding: 0 15px;
                // color: #fff;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }

        .cart-item_wrapper {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .cart-item_img_wrapper {
                align-self: flex-start;
                .cart-item_img {
                    width: 85px;
                    height: 85px;
                    background-size: cover;
                    background-position: center center;
                    margin-right: 1rem;
                    flex-shrink: 0;
                    border-radius: 4px;
                }
                .cart-item_status {
                    display: none;
                    @media screen and (max-width: 360px) {
                        display: block;
                        margin-top: 16px;
                    }
                }
            }
            .cart-item_details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-self: stretch;
                flex-grow: 1;
                flex-shrink: 1;
                color: #111111;

                .cart-item_title {
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 5px;
                    padding-right: 5px;
                    @media screen and (max-width: 768px) {
                        margin-bottom: 2px;
                    }

                    a {
                        color: #111111;
                    }
                }

                .cart-item_title-scientific {
                    font-size: 0.9rem;
                    color: $primary;
                    display: none;
                }
                .cart-totals {
                    display: flex;
                    .cart-item_size {
                        margin-right: 5px;
                    }
                    .cart-item_size, .cart-item_inventory {
                        font-size: 14px;
                        font-weight: 300;
                        margin-bottom: 6px;

                        @media screen and (max-width: 768px) {
                            margin-bottom: 3px;
                        }
                    }
                }

                .cart-item_price {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 18px;

                    @media screen and (max-width: 768px) {
                        margin-bottom: 10px;
                    }
                }

                .cart-item_subtotal {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .cart-item_quantity {
                        border: 2px solid $primary;
                        border-radius: 4px;

                        a,
                        button,
                        span {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                        }

                        span {
                            width: 34px;
                            height: 26px;
                            font-size: 18px;
                            font-weight: 600;

                            @media screen and (max-width: 768px) {
                                width: 34px * 0.8;
                                height: 26px * 0.8;
                                font-size: 18px * 0.8;
                            }
                        }

                        a,
                        button {
                            padding: 0;
                            border: none;
                            transition: all 0.3s;
                            color: $white;
                            font-weight: 600;
                            font-size: 21px;

                            width: 34px;
                            height: 30px;
                            line-height: 30px;

                            @media screen and (max-width: 768px) {
                                width: 34px * 0.9;
                                height: 30px * 0.9;
                                line-height: 30px * 0.9;
                                font-size: 21px * 0.9;
                            }

                            &.remove {
                                background-color: $primary;

                                &.trash {
                                    font-size: 20px;
                                    font-size: 400;

                                    @media screen and (max-width: 768px) {
                                        font-size: 17px;
                                    }
                                }
                            }

                            &.add {
                                background-color: $primary;
                            }

                            &:hover {
                                cursor: pointer;
                                background-color: darken($primary, 15%);
                            }

                            &.disabled {
                                cursor: not-allowed;
                            }
                        }
                    }

                    .cart-item_price-total {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            .cart-item_status {
                align-self: flex-start;
                flex: 0 0 auto;
                .badge-inventory {
                    padding: 1px 10px 3px;
                    span {
                        @extend .ff-body;
                        font-size: 12px;
                    }
                }
                @media screen and (max-width: 360px) {
                    display: none;
                }
            }
        }
    }

    //@.add-to-shopping-list
    .add-to-shopping-list {
        background-color: #fff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
        border-radius: 3px;
        padding: 1rem 32px;
        text-align: center;

        p {
            margin-bottom: 0px;
        }

        .btn {
            display: block;
            font-size: 14px;
            padding-top: 6px;
            padding-bottom: 6px;
            margin-top: 10px;
        }

        // .btn-outline-success {
        //     border: 2px solid $success;
        //     color: $body-color;
        //     font-size: 12px;
        //     padding: 5px 20px;
        //     &:hover,
        //     &:focus,
        //     &:active {
        //         color: #fff;
        //     }
        // }
    }

    > div {
        position: absolute;
        width: 100%;
        padding: 1rem;
    }

    //@header
    .cart-header {
        top: 0;
        height: $header_height;
        border-bottom: 2px solid $accent_green;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        z-index: 3;
        background-color: $accent_green;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

        $base: 2.5rem;

        .count {
            font-size: 1.75rem;
            color: #fff;
            margin-top: 3px;
            margin-bottom: 0;

            @media screen and (max-width: 768px) {
                font-size: 1.5rem;
            }
        }

        .total {
            font-size: 1.75rem;
            width: $base;
        }

        .close-wrap {
            button.close {
                width: $base;
                height: $base;
                opacity: 1;
                color: #fff;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: $base;
                    height: 2px;
                    transition: all 0.3s;
                    background-color: white;
                }

                &:before {
                    transform: rotate(-45deg);
                }

                &:after {
                    transform: rotate(45deg);
                }

                &:hover {
                    &:before,
                    &:after {
                        transform: rotate(0deg);
                        background-color: #fff;
                    }
                }
            }
        }
    }

    //@contents
    .cart-contents {
        z-index: 1;
        height: calc(100vh - #{$header_height} - #{$toggle_height} - #{$footer_height});
        top: $header_height + $toggle_height;
        padding: 0;
        overflow: scroll;
        overflow-x: hidden;
        transition: all 0.3s;
        position: absolute;

        @media screen and (max-width: 768px) {
            height: calc(100vh - #{$header_height} - #{$toggle_height} - #{$footer_height_mobile});
        }

        &:after {
            display: none;
            content: "Cart Empty";
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 1.5rem;
            color: $accent_grey;
            transform: translate(-50%, -50%);
        }
    }

    //@cart-pane-toggle
    .cart-pane-toggle {
        position: absolute;
        padding: 0;
        top: $header-height;
        bottom: $footer_height;
        width: 100%;
        left: 0;
        right: 0;
        max-width: 100%;

        @media screen and (max-width: 768px) {
            bottom: $footer_height_mobile;
        }

        .btn-group {
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            width: 100%;
            max-width: 100%;

            button {
                flex: 0 0 50%;
                width: 50%;
                max-width: 50%;
                font-size: 14px;
                border-radius: 0px;
                padding: 10px 30px;
            }
        }

        ul.slideout-ul {
            list-style: none;
            padding-left: 0;

            li {
                border-bottom: 1px solid #979797;
                font-size: 24px;
                color: #575656;
                letter-spacing: 0;

                a {
                    color: #575656;
                    font-weight: 600;
                    text-decoration: none;
                    display: block;
                    padding: 19px 40px;
                }

                &:hover {
                    background: #d8d8d8;

                    a {
                        color: black;
                    }
                }
            }
        }

        .ctas {
            padding: 16px;
        }
    }

    //@cart-empty
    .cart-empty {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
            text-align: center;

            img {
                width: 5rem;
                height: auto;
            }

            p {
                font-size: 1.5rem;
                color: darken($accent_grey, 30%);
            }

            a {
                font-size: 1.1rem;
            }
        }
    }

    //@cart-update
    .cart-updating {
        background-color: $accent_green;
        color: #fff;
        text-align: center;
        top: ($header_height - $updating_height);
        height: $updating_height;
        overflow: hidden;
        left: 0;
        right: 0;
        z-index: 1;
        transition: all 0.3s;
        padding: 0;

        .cart-updating_wrapper {
            padding: 1rem;
        }
    }

    &.updating {
        .cart-contents {
            height: calc(100vh - #{$header_height} - #{$footer_height} - #{$updating_height});
            top: ($header_height + $updating_height);

            @media screen and (max-width: 768px) {
                height: calc(100vh - #{$header_height} - #{$footer_height_mobile} - #{$updating_height});
            }
        }

        .cart-updating {
            top: $header_height;
        }
    }

    //@footer
    .cart-footer {
        z-index: 3;
        bottom: 0;
        height: auto;
        border-top: 2px solid $accent_brown_dark;
        padding: 0;
        background-color: $accent_brown_light;
        color: #fff;

        > div {
            padding: 1rem;

            p {
                margin: 0;
            }
        }

        .totals {
            border-bottom: 1px solid $accent_brown_dark;

            @media screen and (max-width: 768px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .subtotal {
                font-size: 0.9rem;
                line-height: 1.2rem;
            }

            .tax {
                font-size: 0.9rem;
                line-height: 1.2rem;
            }

            .total {
                font-size: 1.5rem;
                line-height: 1.5rem;
                font-weight: bold;
                margin-top: 0.3rem;

                @media screen and (max-width: 768px) {
                    margin-top: 0;
                }
            }
        }

        .account {
            // display: none;
            background-color: $accent_brown_dark;

            // text-align: center;
            .account-link,
            .logout-link {
                color: #fff;
                text-decoration: underline;

                & + a {
                    margin-left: 1rem;
                }
            }
        }
    }

    //@shopping-list
    $shopping_list_footer_height: 136px;

    .shopping-list {
        position: absolute;
        top: $header-height + $toggle_height;
        height: calc(100vh - #{$header_height} - #{$toggle_height});
        padding: 0;
        overflow: hidden;

        // @shopping list body
        .shopping-list-items {
            position: absolute;
            top: 0;
            // bottom: $shopping_list_footer_height;
            // height: auto;
            height: calc(100% - #{$shopping_list_footer_height});
            left: 0;
            right: 0;
            z-index: 2;
            overflow: scroll;
            overflow-x: hidden;
        }

        // @shopping list footer
        .shopping-list-footer {
            z-index: 3;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            height: auto;
            color: #fff;

            .shopping-list-email {
                padding: 16px;
                border-top: 2px solid $accent_brown_dark;
                background-color: $accent_brown_light;
            }

            .shopping-list-links {
                padding: 16px;
                background-color: $accent_brown_dark;

                a {
                    color: $accent_grey;
                    text-decoration: underline;
                }
            }

            .shopping-list-confirm-empty {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #fff;
                border-top: 2px solid $accent_grey;
                padding: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    text-align: center;
                    margin-bottom: 10px;
                    color: $body-color;
                }

                div {
                    button {
                        & + button {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        // @shopping list items
        .cart-item {
            .cart-item_details {
                justify-content: center;

                .cart-item_title {
                    margin-bottom: 6px;
                }
            }
        }
    }
}

.slide-out-body {
    .cart-contents {
        height: calc(100vh - #{$header_height} - #{$footer_height_small});
    }

    //@cart-pane-toggle
    .cart-pane-toggle {
        top: $header-height;
        bottom: $footer_height_small;
        overflow-y: scroll;
    }
}

// @.add-to-cart
.add-to-cart {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin-bottom: rem-val(65);

    .add-to-cart_header {
        background-color: #fff;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom: 1px solid #e5e5e4;
        padding: 24px 32px;
        text-align: left;

        @media screen and (max-width: 768px) {
            display: none;
        }

        h2 {
            margin-bottom: 0;
            font-size: rem-val(24);
            font-weight: 600;
        }
    }

    .add-to-cart_body {
        background-color: #fff;
        padding: 0 34px 1rem;
        .form-group {
            margin-bottom: 0;
        }

        @media screen and (max-width: 768px) {
            padding: 0 15px;
        }
    }

    .product-allow_preorder {
        background-color: #fff;
        padding: 1rem 32px;
        border-top: 1px solid #e5e5e4;
        border-bottom: 1px solid #e5e5e4;
    }

    .add-to-cart_footer {
        background-color: #fff;
        padding: 1rem 32px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;

        .btn {
            font-size: 16px;
            padding: 0.5rem;
        }
    }
}

.upsell-cart-item {
    border-bottom: 1px solid #e5e5e4;
    .form-group {
        display: flex;
        border-top: 1px solid #e5e5e4;

        .product-image {
            margin-top: 15px;
            width: 85px;
            height: 85px;
            background-size: cover;
            background-position: center center;
            margin-right: 1rem;
            flex-shrink: 0;
            border-radius: 4px;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        .product-option {
            flex-basis: 100%;
        }
    }
}

//@product-option - used on detail page
.product-option {
    padding: 15px 25px;
    border-top: 1px solid #e5e5e4;

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 0.75rem 1.5rem;
    }

    .upsell-cart-item & {
        border: 0;
    }

    @media screen and (max-width: 768px) {
        padding: 15px 11px;
    }

    &:first-child {
        border: none;
    }

    .product-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 16px;

        .quantity-available {
            font-weight: normal;
        }
    }

    .product-cart-quantity {
        border: 2px solid $primary;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;

        button,
        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        span {
            width: 34px;
            height: 26px;
            font-size: 18px;
            font-weight: 600;
        }

        button {
            width: 34px;
            height: 34px;
            line-height: 30px;
            padding: 0;
            border: none;
            transition: all 0.3s;
            color: $primary;
            font-weight: 600;
            font-size: 21px;
            background-color: $white;
            border: 2px solid $primary;
            margin: -2px 0 -2px -2px;
            border-radius: 4px;

            &.remove {
                &.trash {
                    font-size: 20px;
                    font-size: 400;
                }
            }

            &.add {
                margin: -2px -2px -2px 0;
            }

            &:hover {
                cursor: pointer;
                color: $white;
                background-color: $primary;
            }

            &.disabled {
                cursor: not-allowed;
            }
        }
    }

    .product-unavailable {
        line-height: 1.3;
    }
}

//@cart-item - used on cart flyout
.cart-item {
    padding: 1rem 2.5rem 1rem 1rem;
    border-bottom: 1px solid $accent_grey;
    position: relative;
    z-index: 1;
    transition: all 0.3s;

    @media screen and (max-width: 768px) {
        padding: 1rem 2rem 1rem 1rem;

        .notes-wrapper & {
            box-shadow: none;
            padding: 1rem 1rem 1rem 0;
        }
        .notes-wrapper .px-0 & {
            box-shadow: none;
            padding: 1rem 1rem 1rem 1rem;
        }
    }

    td {
        padding: 0 !important;
        .wholesale-checkout & {
            padding: 0.5rem 1rem !important;
        }
    }

    @include on-action() {
        z-index: 2;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);

        .notes-wrapper & {
            box-shadow: none;
        }
    }

    .remove-item {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $gray-600;
            transition: all 0.3s;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:hover {
            &:before,
            &:after {
                transform: rotate(0deg);
                background-color: $accent_green;
            }
        }
    }

    .remove-confirm {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: transparentize(#fff, 0.15);
        transition: all 0.3s;
        opacity: 0;
        display: none;

        &.in {
            display: flex;
            opacity: 1;
        }

        .confirm,
        .cancel {
            padding: 10px 15px !important;

            & + button,
            & + a {
                margin-left: 1rem;
            }
        }

        p {
            margin-bottom: 0.5rem;
            padding: 0 15px;
            // color: #fff;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .cart-item_wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .cart-item_img_wrapper {
            align-self: flex-start;
            .cart-item_img {
                width: 85px;
                height: 85px;
                background-size: cover;
                background-position: center center;
                margin-right: 1rem;
                flex-shrink: 0;
                border-radius: 4px;
            }
            .cart-item_status {
                display: none;
                @media screen and (max-width: 500px) {
                    display: block;
                    margin-top: 16px;
                }
            }
        }

        .cart-item_details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
            flex-grow: 0.7;
            flex-shrink: 1;
            color: #111111;

            .cart-item_title {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 5px;

                @media screen and (max-width: 768px) {
                    margin-bottom: 2px;
                }

                a {
                    color: #111111;
                }
            }

            .cart-item_title-scientific {
                font-size: 0.9rem;
                color: $primary;
                display: none;
            }

            .cart-totals {
                display: flex;
                .cart-item_size {
                    margin-right: 5px;
                }
                .cart-item_size, .cart-item_inventory {
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 6px;

                    @media screen and (max-width: 768px) {
                        margin-bottom: 3px;
                    }
                }
            }

            .cart-item_price {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 18px;

                @media screen and (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }

            .cart-item_subtotal {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .cart-item_quantity {
                    border: 2px solid $primary;
                    border-radius: 4px;

                    a,
                    button,
                    span {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }

                    span {
                        width: 34px;
                        height: 26px;
                        font-size: 18px;
                        font-weight: 600;

                        @media screen and (max-width: 768px) {
                            width: 34px * 0.8;
                            height: 26px * 0.8;
                            font-size: 18px * 0.8;
                        }
                    }

                    a,
                    button {
                        padding: 0;
                        border: none;
                        transition: all 0.3s;
                        color: $white;
                        font-weight: 600;
                        font-size: 21px;

                        width: 34px;
                        height: 30px;
                        line-height: 30px;

                        @media screen and (max-width: 768px) {
                            width: 34px * 0.9;
                            height: 30px * 0.9;
                            line-height: 30px * 0.9;
                            font-size: 21px * 0.9;
                        }

                        &.remove {
                            background-color: $primary;

                            &.trash {
                                font-size: 20px;
                                font-size: 400;

                                @media screen and (max-width: 768px) {
                                    font-size: 17px;
                                }
                            }
                        }

                        &.add {
                            background-color: $primary;
                        }

                        &:hover {
                            cursor: pointer;
                            background-color: darken($primary, 15%);
                        }

                        &.disabled {
                            cursor: not-allowed;
                        }
                    }
                }

                .cart-item_price-total {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
        .cart-item_status {
            align-self: flex-start;
            flex: 0 0 auto;
            .badge-inventory {
                padding: 1px 10px 3px;
                span {
                    @extend .ff-body;
                    font-size: 12px;
                }
            }
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
    }
}

//@.add-to-shopping-list
.add-to-shopping-list {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    border-radius: 3px;
    padding: 1rem 32px;
    text-align: center;

    p {
        margin-bottom: 0px;
    }

    .btn {
        display: block;
        font-size: 14px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 10px;
    }

    // .btn-outline-success {
    //     border: 2px solid $success;
    //     color: $body-color;
    //     font-size: 12px;
    //     padding: 5px 20px;
    //     &:hover,
    //     &:focus,
    //     &:active {
    //         color: #fff;
    //     }
    // }
}

/// @group Checkout

#checkout-cart-totals {
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 166px;
    }
}

.fulfillment-options {
    input:checked + label {
        font-weight: bold;
    }
}
