//Column support styling

/**
 * Needs to be refactored to span all breakpoints
 *
 **/
 @if bootstrap == true {
  // Partial column content container
  .col-contain {
  	@for $i from 1 through 12 {
  		&.col-lg-#{$i},
  		&.col-md-#{$i},
  		&.col-sm-#{$i},
      &.col-xs-#{$i} {
       &.first {
        > div {
         @media screen and (min-width: 1024px) {
          width: ($container-large-desktop * ($i/12) );
          margin-left: auto;
        } 
      }
    }
    &.last {
      > div {
       @media screen and (min-width: 1024px) {
        width: ($container-large-desktop * ($i/12) );
        margin-right: auto;
      } 
    }
  }
}
}
}
}

//Flex box settings

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  > * {
    flex: 1 1 1;
  }
}

$breaks: (md, 768px), (lg, 1024px);
@each $bp in $breaks {
	@media screen and (min-width: nth($bp, 2)) {
		.flex-#{nth($bp, 1)} {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
      flex-direction: row;
    }
  }
}
[class^='flex'],
[class*=' flex'] {

  //Support
  &.center {
    justify-content: center;
  }
  &.between {
    justify-content: space-between;
  }
  &.around {
    justify-content: space-around;
  }
  &.start {
    justify-content: flex-start;
  }
  &.end {
    justify-content: flex-end;
  }
  &.inline {
    display: inline-flex;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.middle {
    align-items: center;
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.columns {
    flex-direction: column;
  }

  //Columns
  @for $i from 2 through 6 {
    &.flex-col-#{$i} {
      > * {
        flex-direction: row;
        flex: 1 0 percentage(calc(1/$i));
      }
    }
  }

}

$breaks:  mapReverse($grid-breakpoints);
@each $bp in $breaks {
  @media screen and (max-width: #{nth($bp, 2)}) {
    [class^='flex'], [class*=' flex'] {

      //Support
      &.center-#{nth($bp, 1)} {
        justify-content: center !important;
      }
      &.between-#{nth($bp, 1)} {
        justify-content: space-between !important;
      }
      &.around-#{nth($bp, 1)} {
        justify-content: space-around !important;
      }
      &.start-#{nth($bp, 1)} {
        justify-content: flex-start !important;
      }
      &.end-#{nth($bp, 1)} {
        justify-content: flex-end !important;
      }
      &.inline-#{nth($bp, 1)} {
        display: inline-flex !important;
      }
      &.top-#{nth($bp, 1)} {
        align-items: flex-start !important;
      }
      &.bottom-#{nth($bp, 1)} {
        align-items: flex-end !important;
      }
      &.middle-#{nth($bp, 1)} {
        align-items: center !important;
      }
      &.nowrap-#{nth($bp, 1)} {
        flex-wrap: nowrap !important;
      }
      &.columns-#{nth($bp, 1)} {
        flex-direction: column !important;
      }
    }
  }
}

.flexrow {
  display: flex;
  align-items: center;
  justify-content: center;
}