
.wholesale-cart-item td {
    &[data-col="size"] {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    tr {
        border: none !important;
        border-bottom: 1px solid #e1e1e1 !important;
    }
    .wholesale-cart-item td {
        border: none !important;
        &[data-col="title"],
        &[data-col="quantity"],
        &[data-col="total"] {
            padding: .5rem !important;
        }
    }
}
