
#cart-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(#000, .2);
    z-index: 3;

    animation: fadeIn .3s ease-in-out;

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
.cart-wrapper {
    background: #fff;
    position: relative;
    z-index: 4;
}
.cart-contents {
    max-height: 50vh;
    // overflow: scroll;

    th {
        position: relative !important;
        top: auto !important;
    }
}
.cart-overview {
    font-size: 16px;
    background: #fff;
    z-index: 2;

    .actions{
        width: auto; 
        
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
}
.btn.btn-success {
    background: #128851;
    &:hover {
        background: darken(#128851, 5%);
    }
}
