
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @media screen and (max-width: 600px) {
        display: block;
    }

    .filter-item {
        flex: 0 0 0;
        align-self: stretch;

        display: flex;
        align-items: center;
        justify-content: center;

        &+.filter-item {
            margin-left: 1rem;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            max-width: unset;
            display: block;
            background: white;

            button.btn{
                width: 100%;
                max-width: unset;
                display: block;
                background: white;

                .icon{
                    display: none;
                }

                span{
                    font-size: 16px;
                }
            }
            &+.filter-item {
                margin-left: 0;
            }
        }
    }
}
button.btn {
    &.btn-filter {
        align-self: stretch;

        width: 100%;
        max-width: 6rem;

        color: #000;
        font-weight: normal;
        font-size: 14px;

        padding: .5rem .5rem;

        border-radius: .5rem;

        // border: 2px solid #fff;

        &.selected {
            box-shadow: none;
            font-weight: 600;
            
            .icon{
                border-color: #128851;
            }
        }

        .icon {
            border: 2px solid #fff;
            background: #F5F5F5;
            padding: .5rem;
            border-radius: .5rem;


            width: 4rem;
            height: 4rem;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 80%;
                max-height: 80%;
                width: auto;
                height: auto;
            }
        }
        span {
            text-align: center;
            text-transform: capitalize;
            display: block;
            line-height: 1;
            margin-top: .25rem; 
        }
    }
}
