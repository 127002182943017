
#natorps_icons_greenhouse {
  /* margin-bottom: 10px; */
}
.natorps_icons_greenhouse .cls-1 {
  fill: none;
}
.natorps_icons_greenhouse .cls-2 {
  fill: #fff;
}
