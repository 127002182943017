
a.modal-dismiss {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
    @media screen and (max-width: 600px){
        color: white !important;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        right: 0;
        top: 0;
        padding: 1rem;
    }
}
.h1.title {
    font-size: 42px;
    font-weight: bold;
}
.h2 {
    font-size: 24px;
    font-weight: bold;
}
.plant-image {
    img {
        width: 100%;
        height: auto;
    }
}
.list-group {
    box-shadow: 0px 3px 3px rgba(#000, .2);
}
.modal.modal-plant_detail {
    overflow: scroll;
    .modal-content {
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif !important;

        .modal-header {
            border-bottom: none;
            .row {
                .plant-image {
                    align-self: stretch;
                    background-size: cover;
                    background-position: center center;

                        .picture-credit {
                            position: absolute;
                            bottom:0;
                            right:1rem;
                            color: #FFF;
                            max-width: 200px;
                            text-shadow: 1px 1px #111111;
                        }
                }
            }
        }
        .modal-footer {
            border-top: none;
        }

        ul.list-group{
            border: 1px solid rgba(0, 0, 0, 0.125);


            li.list-group-item{
                border: none;
                // padding: 0;
                padding: .5rem 4rem;
                @media screen and (max-width: 600px){
                    padding: 0.5rem 0.5rem;
                }


                div.plant-meta-item{
                    padding-bottom: 1rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
    
}
