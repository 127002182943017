
#natorps_icons_plants {
  margin-bottom: 10px;
}
.natorps_icons_plants .cls-1 {
  fill: none;
}
.natorps_icons_plants .cls-2 {
  fill: #fff;
}
