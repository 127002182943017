
table.table-totals tr.total {
    font-size: 1.5rem;
    border-bottom: 1px solid #eceeef;
}
.cart-item {
    td {
        padding: .5rem 1rem !important;
    }
}
.finalize-order{

    p{
        font-size: 16px;

        &.disclaimer{
            max-width: 300px;
            margin: auto;
            font-size: 14px;
        }
    }
}
input[type="radio"]{
    margin-right: 6px !important;
}
h2{
    margin-bottom: .5rem !important;
}
.card.checkout-card{
    margin-bottom: 50px;

    @media screen and (max-width: 600px){
        margin-bottom: 24px;
    }
}
#checkout-wrapper {
    padding-left: 15px;
    padding-right: 15px;
}
